// React imports
import React from 'react'
// import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom'
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Components
import Header from 'components/organisms/header/Header'
import PomoWrapper from 'components/templates/pomodoro/PomoWrapper'
import TodoWrapper from 'components/templates/todo/TodoWrapper'
import UrlWrapper from 'components/templates/url/UrlWrapper'


const App = () => {
	return (
		<>
			<Header />
			<Switch>  
				<Route path='/' exact component={Home} />
				<Route path='/timer' exact component={PomoWrapper} />
				<Route path='/todo' exact component={TodoWrapper} />
				<Route path='/urls' exact component={UrlWrapper} />
			</Switch>
		</>
	)
}

const Home = () => {
	return (
		<div>
			<h1>Home</h1>
		</div>
	)
}

export default App
