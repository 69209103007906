// React imports
import React from 'react'
import './urlWrapper.css'


// React component
const UrlWrapper = () => {
	return (
		<>
      <main>
        <h1>Url Shortner</h1>
        <small>Short them up!</small>
      </main>
		</>
	)
}

export default UrlWrapper
