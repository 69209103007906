// React imports
import React from 'react'
import './pomoWrapper.css'

// Components
import PomoElement from 'components/organisms/pomo/PomoElement'
import SettingsContextProvider from 'context/pomodoro/PomoContext'

// React component
const PomoWrapper = () => {
	return (
      <SettingsContextProvider>
        <main>
          <h1>Pomodoro</h1>
          <PomoElement />
        </main>
      </SettingsContextProvider>
	)
}

export default PomoWrapper
