// React imports
import React, { useContext, useEffect} from 'react'
import './pomoElement.css'

// Components
import Button from 'components/atoms/Button/Button.js'
import PomoNav from 'components/molecules/pomo/PomoNav/PomoNav'
import PomoCountdown from 'components/molecules/pomo/PomoCount/PomoCountdown.js'

// Context
import { PomoContext } from 'context/pomodoro/PomoContext'

// React component
const PomoElement = () => {
	const { 
    pomodoro,
    executing,
    startAnimate,
    children,
    startTimer,
    pauseTimer,
    updateExecute,
    setCurrentTimer,
    SettingsBtn   } = useContext(PomoContext)
  
  useEffect(() => {updateExecute(executing)}, [executing, startAnimate])

  return (
		<>
      { pomodoro == 0 ?
        <PomoNav />
        :
        <> 
          <ul className="labels">
            <li>
              <Button title='Work' activeClass={executing.active === 'work' ? 'active-label' : ''} _callback={() => setCurrentTimer('work')} />
            </li>
            <li>
              <Button title='Short Break' activeClass={executing.active === 'short' ? 'active-label' : ''} _callback={() => setCurrentTimer('short')} />              
            </li>
            <li>
              <Button title='Long Break' activeClass={executing.active === 'long' ? 'active-label' : ''} _callback={() => setCurrentTimer('long')} />
            </li>
          </ul>
          <Button title='Reset timer' _callback={SettingsBtn} />
          <section className='counter'>
            <PomoCountdown
              key={pomodoro} 
              timer={pomodoro} 
              animate={startAnimate}
            >
              {children}
            </PomoCountdown>
          </section>

          <div className="button-wrapper">
            <Button title="Start" activeClass={!startAnimate ? 'active' : undefined} _callback={startTimer} />
            <Button title="Pause" activeClass={startAnimate ? 'active' : undefined} _callback={pauseTimer} />
          </div>
        </>
      }
      {/*<Countdown />*/}
		</>
	)
}

export default PomoElement
 