// React imports
import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'

// React component
const Header = () => {
	return (
		<header>
			<nav>
        <Link to='/'><h3>JorTools</h3></Link>
				
				<ul> 
					<li> 
          <Link to='/timer'>Pomodoro</Link>
					</li>
					<li> 
						<Link to='/todo'>Todo's</Link>
					</li>
          <li> 
						<Link to='/urls'>Url Shortner</Link>
					</li>
				</ul>
			</nav>
		</header>
	)
}

export default Header
