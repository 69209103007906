import React, { createContext, useState } from 'react'

export const PomoContext = createContext()

const SettingsContextProvider = (props) => {

  const [pomodoro, setPomodoro] = useState(0)
  const [executing, setExecuting] = useState({})
  const [startAnimate, setStartAnimate] = useState(false)



  // Start, Pause, Stop timer
  function startTimer() {
    setStartAnimate(true)
  }
  
  function pauseTimer(){
    setStartAnimate(false)
  }
  
  function stopAnimate (){
    setStartAnimate(false)
  }

  function setCurrentTimer(active_state) {
    updateExecute({
      ...executing, 
      active: active_state
    })
    setTimerTime(executing)
  }

  const SettingsBtn = () => {
    setExecuting({})
    setPomodoro(0)
  }

  const updateExecute = (updatedSettings) => {
    setExecuting(updatedSettings)
    setTimerTime(updatedSettings)
  }



  const setTimerTime = (evaluate) => {
    switch (evaluate.active) {
      case 'work':
          setPomodoro(evaluate.work)
          break;
      case 'short':
          setPomodoro(evaluate.short)
          break;
      case 'long':
          setPomodoro(evaluate.long)
          break;
          default:
              setPomodoro(0)
          break;
    }
  }

  const children = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60  
    return `${minutes}:${seconds}`
  }

  return (
    <PomoContext.Provider value={{
      pomodoro, 
      executing,
      updateExecute, 
      startAnimate, 
      startTimer,
      pauseTimer,
      children,
      SettingsBtn,
      setCurrentTimer,
      stopAnimate
    }}>
      {props.children}
    </PomoContext.Provider>
  )
}

export default SettingsContextProvider 