// React imports
import React from 'react'
import './todoWrapper.css'

// React component
const TodoWrapper = () => {
	return (
		<>
      <main>
        <h1>Todo</h1>
        <small>Be productive!</small>
      </main>
		</>
	)
}

export default TodoWrapper
