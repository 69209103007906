// React imports
import React, { useContext, useState } from 'react'
import './pomoNav.css'

// Context
import { PomoContext } from 'context/pomodoro/PomoContext'

// Components

// React component
const PomoNav = () => {
  const { updateExecute } = useContext(PomoContext)

  const handleChange = input => {
    const {name, value} = input.target
    switch (name) {
      case 'work':
        setNewTimer({
          ...newTimer,
          work: parseInt(value)
        })
      break;
    case 'shortBreak':
      setNewTimer({
        ...newTimer,
        short: parseInt(value)
      })
      break;
    case 'longBreak':
      setNewTimer({
        ...newTimer,
        long: parseInt(value)
      })
      break;
      default:
      break;
    }
  }

  const [newTimer, setNewTimer] = useState({
    work: 25.0,
    short: 5.0,
    long: 45,
    active: 'work'
  })

	const handleSubmit = (e) => {
		e.preventDefault()
		updateExecute(newTimer)
	}

	return (
		<>
			<form noValidate onSubmit={handleSubmit} className='pomoForm'>
				<div>
          <fieldset> 
            <label>Work time:</label>
            <input type="number" name="work" onChange={handleChange} value={newTimer.work} />
          </fieldset>
          <fieldset> 
            <label>Short Break time:</label>
            <input type="number" name="shortBreak" onChange={handleChange} value={newTimer.short} />
          </fieldset>
          <fieldset> 
            <label>Long break time:</label>
            <input type="number" name="longBreak" onChange={handleChange} value={newTimer.long} />
          </fieldset>
				</div>
        <button type='submit'>Set Timer</button>
      </form>
		</>
	)
}

export default PomoNav
