// React imports
import React, { useContext } from 'react'

// Component imports
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

// Context
import { PomoContext } from 'context/pomodoro/PomoContext'

// React component
const PomoCountdown = ({key, timer, animate, children}) => {
  const { stopAnimate } = useContext(PomoContext)

  return (
    <CountdownCircleTimer
      key={key}
      isPlaying={animate}
      duration={timer * 60}
      colors={[
        ['#FE6F6B', 0.33],
        ['#FE6F6B', 0.33],
        ['#FE6F6B', 0.33],
      ]}
      strokeWidth={6}
      size={220}
      trailColor="#151932"
      onComplete={ () => {
        stopAnimate()
      }}
    >
      {children}
    </CountdownCircleTimer>
  )
}

export default PomoCountdown
